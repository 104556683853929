import { ImageWithFallback } from '@/components/atoms/ImageWithFallback'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { EllipsisBox } from '@/components/molecules/text/EllipsisBox'
import { InvertedRadiusBox } from '@/components/molecules/text/InvertedRadiusBox'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { NavigationParams, useNavigation } from '@/core/hooks/useNavigation'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import { ReactNode } from 'react'
import { NavigationLink } from '../navigation/NavigationLink'

export const DiscoverCardvNext = ({
  bannerSrc,
  avatarSrc,
  content,
  status,
  extraContent,
  title,
  gameTitle,
  gameSrc,
  pinned,
  navigation,
  showAvatar,
  showBanner,
  googleTag
}: {
  googleTag?: string
  showBanner?: boolean
  showAvatar?: boolean
  pinned?: boolean
  avatarSrc?: string
  gameTitle?: ReactNode | string
  gameSrc?: string
  status?: ReactNode | string
  title?: ReactNode | string
  bannerSrc?: string | StaticImport
  content?: ReactNode
  extraContent?: ReactNode
  navigation?: NavigationParams | string
}) => {
  const { featCompetitionDiscoveryAvatars, featCompetitionDiscoveryBanners } =
    useFeatures()
  const theme = useTheme()

  const renderBanner = () => {
    if (!featCompetitionDiscoveryBanners || !showBanner) return null
    return (
      <Grid item>
        <Box style={{ position: 'relative', width: '100%', height: '100%' }}>
          <CardMedia sx={{ aspectRatio: '16/9', width: '100%' }}>
            <ImageWithFallback
              src={bannerSrc!}
              alt='Banner for Competition Discovery Card'
              style={{ objectFit: 'cover' }}
              fill
              priority
            />
          </CardMedia>
        </Box>
      </Grid>
    )
  }
  const renderAvatar = () => {
    if (!featCompetitionDiscoveryAvatars) return null
    if (showBanner && showAvatar) {
      return (
        <Grid item sx={{ minWidth: 80 }}>
          <CardMedia
            sx={{
              position: 'relative',
              width: '100%'
            }}
          >
            <AvatarText
              size='xl'
              src={avatarSrc}
              variant='rounded'
              sx={{
                position: 'absolute',
                top: -30,
                width: 70,
                height: 70
              }}
            />
          </CardMedia>
        </Grid>
      )
    } else {
      return (
        <Grid item>
          <CardMedia>
            <AvatarText size='xl' src={avatarSrc} variant='rounded' />
          </CardMedia>
        </Grid>
      )
    }
  }

  const renderPinned = () => {
    return (
      <Box
        sx={{
          zIndex: 1,
          backgroundColor: theme.palette.secondary.main,
          position: 'absolute',
          top: 0,
          right: 0,
          transform: 'scaleX(-1)'
        }}
      >
        <InvertedRadiusBox pb={0} borderTopLeftRadius={10}>
          <RcIconButton
            disableRipple
            sx={{ zIndex: 1 }}
            icon={['fal', 'star']}
            size='small'
          />
        </InvertedRadiusBox>
      </Box>
    )
  }

  const renderGameTitle = () => {
    return (
      <Paper elevation={2} square>
        <Stack direction='row' alignItems='flex-end' spacing={2} p={4} pt={2}>
          <AvatarText size='xs' src={gameSrc || ''} variant='rounded' />
          <Typography variant='body2'>{gameTitle}</Typography>
        </Stack>
      </Paper>
    )
  }
  const { getPath } = useNavigation()
  const to = getPath(navigation || '')
  const isHref = to.startsWith('http')
  const ActionAreaProps = {
    component: NavigationLink,
    googleTag,
    to: isHref ? undefined : to,
    href: isHref ? to : undefined,
    scroll: true,
    underline: 'none'
  }

  return (
    <Card elevation={2} sx={{ width: '100%' }}>
      <CardActionArea
        {...ActionAreaProps}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          alignItems: 'stretch',
          height: '100%'
        }}
      >
        <Paper elevation={3} sx={{ flexGrow: 1, borderRadius: 2 }}>
          {bannerSrc ? renderBanner() : null}
          <CardContent
            sx={{
              p: 0
            }}
          >
            <Stack
              direction='column'
              spacing={2}
              pb={2}
              pt={showBanner ? 2 : 4}
              px={4}
            >
              <Stack
                direction='row'
                spacing={2}
                alignItems='flex-start'
                sx={{ minHeight: 40, mb: !showBanner && !showAvatar ? 1 : 0 }}
              >
                {showAvatar ? renderAvatar() : null}
                <Stack spacing={2} pt={showBanner ? 0 : 2}>
                  <EllipsisBox
                    maxWidth={!showBanner && !showAvatar ? 330 : 220}
                  >
                    <Typography variant='h4'>{title}</Typography>
                  </EllipsisBox>

                  <Stack direction='row' spacing={2}>
                    {extraContent ? null : content}
                    <EllipsisBox
                      maxWidth={!showBanner && !showAvatar ? 330 : 230}
                    >
                      {status ? status : null}
                    </EllipsisBox>
                  </Stack>
                </Stack>
              </Stack>
              {extraContent}
            </Stack>
          </CardContent>
          {pinned ? renderPinned() : null}
        </Paper>
        {gameTitle && gameSrc ? renderGameTitle() : null}
      </CardActionArea>
    </Card>
  )
}
